import { PROMISE_SUPERSEDED_ERROR_MESSAGE } from '@noths/polaris-client-utils';
import { createSlice } from '@reduxjs/toolkit';

import { reduxCategoryBuilder } from 'src/redux/products/builders';
import { healthCheckProducts } from './thunks/healthCheckProducts';
import { loadPageOfProducts } from './thunks/loadPageOfProducts';
import { requestProducts } from './thunks/requestProducts';
import { updateFilters } from './thunks/updateFilters';
import type { ProductsState, RequestProductsActionPayloadData } from './types';

export const initialState: Readonly<ProductsState> = {
  category: reduxCategoryBuilder(),
  currencyCode: 'GBP',
  items: {},
  currentPage: 0,
  pageLoading: null,
  totalPages: 0,
  totalProducts: 0,
  productsReturnedCount: 0,
  queryType: '',
  navigation: [],
  isLoading: false,
  isUpdateFiltersLoading: false,
  providerAttributionToken: null,
  zeroExactResults: null,
  pinnedResultCount: null,
  providerMetadata: null,
};

const populateProducts = (
  state: ProductsState,
  data: RequestProductsActionPayloadData,
  replaceProducts: boolean,
) => {
  const {
    category,
    currencyCode,
    currentPage,
    navigation,
    partner,
    pinnedResultCount = null,
    products,
    productsReturnedCount,
    providerAttributionToken,
    providerMetadata,
    queryType,
    totalPages,
    totalProducts,
    zeroExactResults = null,
  } = data;

  const items = replaceProducts
    ? { [currentPage]: products }
    : {
        ...state.items,
        ...{ [currentPage]: products },
      };

  return {
    ...state,
    category,
    currencyCode,
    productsReturnedCount,
    queryType,
    currentPage:
      replaceProducts || currentPage > state.currentPage ? currentPage : state.currentPage,
    pageLoading: null,
    totalPages,
    totalProducts,
    partner,
    navigation,
    items,
    isLoading: false,
    error: null,
    providerAttributionToken,
    zeroExactResults,
    pinnedResultCount,
    providerMetadata,
  } as ProductsState;
};

export const { actions, reducer } = createSlice({
  name: 'products',
  initialState,
  reducers: {
    setInitialProductPage: (state, { payload }) => {
      state.initialProductPage = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(healthCheckProducts.fulfilled, (state, { payload }) => {
        if (payload.data) {
          return populateProducts(state, payload.data, false);
        }

        return state;
      })
      .addCase(requestProducts.fulfilled, (state, { meta, payload }) => {
        const { replaceProducts = true } = meta.arg;

        if (payload.data) {
          state = populateProducts(state, payload.data, replaceProducts);
        }

        if (payload.redirectUrl) {
          state.redirectUrl = payload.redirectUrl;
          return;
        }

        return state;
      })

      .addCase(requestProducts.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(requestProducts.rejected, (state, action) => {
        if (action.error && action.error.message === PROMISE_SUPERSEDED_ERROR_MESSAGE) {
          return;
        }

        state.items = {};
        state.error = action.error;
        state.isLoading = false;
      })
      .addCase(updateFilters.pending, (state) => {
        state.isUpdateFiltersLoading = true;
        state.items = {};
        state.pageLoading = 1;
      })
      .addCase(loadPageOfProducts.pending, (state, { meta }) => {
        state.pageLoading = Number(meta.arg);
      })
      .addCase(updateFilters.fulfilled, (state) => {
        state.isUpdateFiltersLoading = false;
      });
  },
});
