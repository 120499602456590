import { getFeatureFlags } from '@noths/polaris-client-feature-flags';
import type { IncomingMessage } from 'http';

import { SearchPersonalisation } from './featureFlags';

export const getFeatureFlagsWithMatchers = (req: IncomingMessage) => {
  return getFeatureFlags(req, {
    collapseFlagNames: [
      { newName: SearchPersonalisation.NAME, oldNameMatcher: /^search_personalisation/m },
    ],
  });
};
