/* istanbul ignore file */
import React from 'react';
import { Provider } from 'react-redux';
import { CookieModal } from '@noths/polaris-client-gdpr-compliance';
import { GTMWithDataLayer } from '@noths/polaris-client-google-analytics';
import { setLocaleCookies } from '@noths/polaris-client-localisation';
import type { BaseAppProps } from '@noths/polaris-client-next-components';
import { BaseApp } from '@noths/polaris-client-next-components';
import { RECOMMENDATIONS_SCRIPT_URL } from '@noths/polaris-client-recommendations';
import { SkipLinks, useSmartAppBanner } from '@noths/polaris-client-site-chrome-ui';
import { FavouritesContainer } from '@noths/polaris-client-user-favourites';
import type { Merge } from '@noths/polaris-dev-ts-recipes';
import type { AppContextPlusCookies } from '@noths/polaris-dev-ts-types';
import type { Store } from '@reduxjs/toolkit';
import { ConnectedRouter } from 'connected-next-router';
import type { AppProps } from 'next/app';
import NextApp from 'next/app';
import getConfig from 'next/config';
import Head from 'next/head';

import { PRODUCT_CONTAINER_WRAPPER, PRODUCTS_GRID_WRAPPER } from 'src/constants/elementIds';
import { isProd } from 'src/environment';
import { getFeatureFlagsWithMatchers } from 'src/featureFlags/getFeatureFlagsWithMatchers';
import type { ReduxApplicationState } from 'src/redux/combinedReducer';
import { useStore } from 'src/redux/store';
import { fetchGlobalContent } from 'src/services/contentstack/fetchGlobalContent';
import { filterNavFilterScript, getServerSidePageEventObject } from 'src/tracking/events/pageEvent';
import type { NextConfig } from 'src/types/nextConfig';
import type { PageProps } from 'src/types/page';
/*
  Enable mocks required for development for mock service worker.
  To enable mock service worker run:

  npx msw init ./public
*/
if (process.env.NODE_ENV === 'development') {
  // require('src/components/organisms/Filter/mocks/universal').worker.start();
  // require('src/components/organisms/FilterMenu/mocks/universal').worker.start();
  // require('src/components/organisms/Filter/mocks/withDelay/universal').worker.start();
  // require('src/components/organisms/ProductPage/mocks/universal').worker.start();
}

const nextConfig = getConfig() as NextConfig;
const AppComponent = BaseApp(nextConfig);
const { appVersion, version } = nextConfig.publicRuntimeConfig;

type CustomAppProps = Merge<
  BaseAppProps & AppProps,
  {
    pageProps: PageProps;
  }
>;

const App = (props: CustomAppProps) => {
  const { topBannerData, updatedReduxState } = props.pageProps || {};
  const store = useStore(props, updatedReduxState as ReduxApplicationState);
  const products = updatedReduxState?.products;
  const productsListQuery = updatedReduxState?.productsListQuery;
  const searchedTerm = productsListQuery?.searchTerm || '';

  // Preload first row of product images to improve LCP
  // Note: for LCP metric, it may be sufficient to preload first product image only - further testing needed
  // As this code runs on server, we can't be sure whether to preload 2 or 3 images, so 3 preloaded
  const productItems = products?.items || {};
  const productPageIndexes = Object.keys(productItems);
  const firstProductPage = Array.isArray(productPageIndexes) && productItems[productPageIndexes[0]];
  const firstProductImage =
    firstProductPage?.length > 0 &&
    firstProductPage[0]?.images[0]?.href?.replace('{size}', 'preview');
  const secondProductImage =
    firstProductPage?.length > 1 &&
    firstProductPage[1]?.images[0]?.href?.replace('{size}', 'preview');
  const thirdProductImage =
    firstProductPage?.length > 2 &&
    firstProductPage[2]?.images[0]?.href?.replace('{size}', 'preview');

  useSmartAppBanner();
  const pageData = JSON.stringify(
    getServerSidePageEventObject(version, appVersion, updatedReduxState as ReduxApplicationState),
  );

  return (
    <>
      <Head>
        {firstProductImage && <link as="image" href={firstProductImage} rel="preload" />}
        {secondProductImage && <link as="image" href={secondProductImage} rel="preload" />}
        {thirdProductImage && <link as="image" href={thirdProductImage} rel="preload" />}
        <CookieModal useTestScript={!isProd()} />
      </Head>
      <GTMWithDataLayer customScript={filterNavFilterScript} pageEventData={pageData} />
      <script src={RECOMMENDATIONS_SCRIPT_URL} />
      <SkipLinks
        links={[
          { content: 'Skip to content', sectionId: PRODUCT_CONTAINER_WRAPPER },
          { content: 'Skip to products', sectionId: PRODUCTS_GRID_WRAPPER },
        ]}
      />
      <Provider store={store as Store}>
        <ConnectedRouter>
          <FavouritesContainer nextConfig={nextConfig} />
          <AppComponent {...props} searchedTerm={searchedTerm} topBannerData={topBannerData} />
        </ConnectedRouter>
      </Provider>
    </>
  );
};

/*
  Necessary to disable next's Automatic Static Optimization feature.
  Without it this page will be statically compiled, and throw an error in
  `src/utils/gaPageEvent` due to undefined `pageProps.updatedReduxState`.

  Added in https://github.com/notonthehighstreet/polaris/pull/597.
  See below discussion for more details:
  https://github.com/notonthehighstreet/polaris/pull/597#discussion_r558444865
*/

App.getInitialProps = async (appContext: AppContextPlusCookies): Promise<BaseAppProps> => {
  const {
    ctx: { req, res },
    router,
  } = appContext;

  if (req && res) {
    setLocaleCookies({ req, res });
  }

  const featureFlags = (req && getFeatureFlagsWithMatchers(req)) || {};
  const appProps = await NextApp.getInitialProps(appContext);

  if (router.route === '/404') {
    const [navData] = await fetchGlobalContent({ featureFlags });

    return {
      ...appProps,
      navData,
      featureFlags,
    };
  }

  return {
    ...appProps,
    featureFlags,
  };
};

export default App;
