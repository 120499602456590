import { createSlice } from '@reduxjs/toolkit';

import type { ReduxContentState } from './types';

export const initialState: ReduxContentState = {
  canonicalTag: '',
  description: '',
  heading: '',
  indexable: false,
  metaTagDescription: '',
  pageTitleOverride: null,
};

export const { actions, reducer } = createSlice({
  name: 'content',
  initialState,
  reducers: {},
});
