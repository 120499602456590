import { batch } from 'react-redux';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { actions as productListQueryActions } from 'src/redux/productsListQuery/slice';
import { adaptProductsDataToProductsActionPayload } from '../adapters/browse-data-api/adaptProductsDataToProductsActionPayload';

export const healthCheckProducts = createAsyncThunk(
  'products/healthCheck',
  async (_, { dispatch }) => {
    // Dynamically import json file to prevent it being included on the client. Healthcheck request only happens server side.
    const { healthCheckCategoryResponseBody } = await import(
      'src/services/browse-data-api/fixtures/healthCheckCategoryResponseBody'
    );
    const category = healthCheckCategoryResponseBody.category!;

    batch(() => {
      dispatch(
        productListQueryActions.setProductsListQuery({
          categoryIDs: [category.id],
        }),
      );
    });

    return {
      data: adaptProductsDataToProductsActionPayload(healthCheckCategoryResponseBody),
    };
  },
);
