/* istanbul ignore file */
import { sendGAEvent } from '@noths/polaris-client-google-analytics';
import type { EventsMap } from '@noths/polaris-client-next-redux';

import { productListClick } from 'src/components/organisms/ProductList/modules/actions';
import type { ReduxApplicationState } from 'src/redux/combinedReducer';
import type { BrowseDataAPIProduct } from 'src/services/browse-data-api/types/BrowseDataAPIProduct';
import { getProductClickTrackingEvent } from 'src/tracking/events/productClick';
import { getFieldObjectList, getProductFieldObject } from 'src/utils/googleAnalytics';

export const GAEventsMap: EventsMap<ReduxApplicationState> = {
  [productListClick.type]: (state, _nextState, action) => {
    const {
      payload: { destinationUrl, linkOpensInSameWindow, productCode, productUrl },
    } = action as ReturnType<typeof productListClick>;

    const { category, items, productsReturnedCount } = state.products;
    const { pageType } = state.navigation;
    const { categoryIDs, searchTerm } = state.productsListQuery;
    const categoryId = categoryIDs?.[0];
    const categoryName = category?.name;

    const clickedProduct = Object.keys(items)
      .reduce((curr, key) => curr.concat(items[key]), [] as BrowseDataAPIProduct[])
      .find((prod) => prod.code === productCode)!;

    const event = getProductClickTrackingEvent({
      eventCategory: 'Product listings',
      destinationUrl,
      eventLabel: 'null',
      actionFieldList: getFieldObjectList(
        clickedProduct,
        searchTerm!,
        pageType,
        categoryName,
        clickedProduct.isExpandedSearch ? ' - expanded' : undefined,
      ),
      products: [
        getProductFieldObject(clickedProduct, items, productsReturnedCount, categoryId, pageType),
      ],
    });

    sendGAEvent(event).then(() => {
      if (linkOpensInSameWindow) {
        window.location.assign(productUrl);
      }
    });
  },
};
