import { selectFeatureFlags } from '@noths/polaris-client-user-configuration';
import { createAsyncThunk, isRejected } from '@reduxjs/toolkit';
import type { ParsedUrlQueryInput } from 'querystring';

import { SearchPersonalisation } from 'src/featureFlags/featureFlags';
import type { ReduxApplicationState } from 'src/redux/combinedReducer';
import { selectPageType } from 'src/redux/navigation/selectors';
import {
  CATEGORY_NOT_FOUND_ERROR,
  PARTNER_NOT_FOUND_ERROR,
} from 'src/redux/products/thunks/thunkErrors';
import type { RequestProductsActionPayload, TriggerName } from 'src/redux/products/types';
import {
  getCategoryListingsData,
  getPartnerListingsData,
  getSearchListingsData,
} from 'src/services/browse-data-api/getListingsData';
import { PageType } from 'src/types/navigation';
import { logger } from 'src/utils/serverLogger';
import { adaptProductsDataToProductsActionPayload } from '../adapters/browse-data-api/adaptProductsDataToProductsActionPayload';

export interface RequestProductsOptions {
  cookie?: string;
  query: ParsedUrlQueryInput;
  replaceProducts?: boolean;
  requestIsLoadTest?: boolean;
  triggerName?: TriggerName;
}

export const requestProducts = createAsyncThunk<
  RequestProductsActionPayload,
  RequestProductsOptions
>(
  'products/requestProducts',
  async ({ cookie, query, requestIsLoadTest, triggerName }, { getState }) => {
    const state = getState() as ReduxApplicationState;
    const { currencyCode, deliveryZone } = state.userConfiguration;

    const commonGetDataArgs = {
      cookie,
      currencyCode,
      ...(deliveryZone && { deliveryZone }),
    };
    let response;

    switch (selectPageType(state)) {
      case PageType.Search: {
        const searchPersonalisationFeatureFlag =
          selectFeatureFlags(state)?.[SearchPersonalisation.NAME];
        const servingConfigMap = {
          [SearchPersonalisation.PERSONALISED]: 'default_search_personalised',
          [SearchPersonalisation.UNPERSONALISED]: 'default_search',
        };

        response = await getSearchListingsData({
          ...commonGetDataArgs,
          query,
          requestIsLoadTest,
          loggedInUserId: state.user.cognitoID || undefined,
          searchTerm: state.productsListQuery.searchTerm!,
          servingConfig:
            searchPersonalisationFeatureFlag && servingConfigMap[searchPersonalisationFeatureFlag],
          userId: state.userConfiguration.gaVisitorId,
        });
        break;
      }
      case PageType.Category: {
        const { previewing, ...restOfQuery } = query;
        const [categoryId] = state.productsListQuery.categoryIDs;

        if (!categoryId) {
          logger.warn('category ID missing for category listing page');
        }

        response = await getCategoryListingsData({
          ...commonGetDataArgs,
          categoryId,
          ...(previewing ? { previewing: true } : {}),
          query: restOfQuery, // when categoryId is set, fetchBrowseData will not send query.path
          loggedInUserId: state.user.cognitoID || undefined,
          userId: state.userConfiguration.gaVisitorId,
        });

        if (response === null) {
          throw CATEGORY_NOT_FOUND_ERROR;
        }

        break;
      }
      case PageType.Partner: {
        response = await getPartnerListingsData({
          ...commonGetDataArgs,
          partnerShortcode: state.productsListQuery.partnerShortcode!,
          query,
          loggedInUserId: state.user.cognitoID || undefined,
          userId: state.userConfiguration.gaVisitorId,
        });
        if (response === null) {
          throw PARTNER_NOT_FOUND_ERROR;
        }
        break;
      }
      default:
        console.error('unknown page type');
    }

    if (response?.redirectUrl) {
      return { redirectUrl: response.redirectUrl };
    }

    const payload: RequestProductsActionPayload = {
      data: adaptProductsDataToProductsActionPayload(response.data),
      triggerName,
    };

    return payload;
  },
);

export const requestProductsIsRejected = isRejected(requestProducts);
