import type { CurrencyCode, Navigation } from '@noths/polaris-dev-ts-types';
import type { SerializedError } from '@reduxjs/toolkit';

import type { ReduxProductsCategory } from 'src/redux/products/types/reduxProductsCategory';
import type { BrowseDataAPIFilter } from 'src/services/browse-data-api/types/BrowseDataAPIFilter';
import type { BrowseDataAPIProduct } from 'src/services/browse-data-api/types/BrowseDataAPIProduct';
import type { BrowseDataAPISortingOption } from 'src/services/browse-data-api/types/BrowseDataAPISortingOption';
import type { Partner } from 'src/types/partner';

export enum TriggerName {
  LOAD_MORE_PRODUCTS = 'loadMoreProducts',
  LOAD_PREVIOUS_PRODUCTS = 'loadPreviousProducts',
}

export interface ProviderMetadata {
  filter: string;
  pageCategories: string[];
}

export interface RequestProductsActionPayloadData {
  category: ReduxProductsCategory | null;
  currencyCode: CurrencyCode;
  currentPage: number;
  errorCode?: string;
  errorMessage?: string;
  filters: BrowseDataAPIFilter[];
  navigation: Navigation[];
  partner?: Partner | null;
  perPage: number;
  pinnedResultCount?: number;
  products: BrowseDataAPIProduct[];
  productsReturnedCount: number;
  providerAttributionToken: string | null;
  providerMetadata: ProviderMetadata | null;
  queryType: string | null;
  sortingOptions: BrowseDataAPISortingOption[];
  term: string | null;
  totalPages: number;
  totalProducts: number;
  zeroExactResults?: boolean;
}

export interface RequestProductsActionPayload {
  data?: RequestProductsActionPayloadData;
  redirectUrl?: string;
  triggerName?: TriggerName;
}

export interface ProductsState {
  category: ReduxProductsCategory | null;
  currencyCode: CurrencyCode;
  currentPage: number;
  deliveryZone?: string;
  error?: SerializedError | null;
  initialProductPage?: number;
  isLoading: boolean;
  isUpdateFiltersLoading: boolean;
  items: Record<number, BrowseDataAPIProduct[]>;
  navigation: Navigation[];
  pageLoading: number | null;
  partner?: Partner;
  pinnedResultCount?: number | null;
  productsReturnedCount: number;
  providerAttributionToken: string | null;
  providerMetadata: ProviderMetadata | null;
  queryType: string;
  redirectUrl?: string;
  showFewResultsMessage?: boolean;
  totalPages: number;
  totalProducts: number;
  zeroExactResults?: boolean | null;
}
