import { sendGAEvent } from '@noths/polaris-client-google-analytics';
import type { EventsMap } from '@noths/polaris-client-next-redux';
import { isBrowser } from '@noths/polaris-client-utils';

import { selectUserAppliedFilterTitles } from 'src/components/organisms/Filter/modules/selectors/selectors';
import { selectSelectedSortingOption } from 'src/components/organisms/FilterMenu/modules/selectors';
import type { ReduxApplicationState } from 'src/redux/combinedReducer';
import {
  PRODUCT_IMPRESSION_BATCH_SIZE,
  PRODUCT_IMPRESSION_TIMEOUT_MS,
} from 'src/redux/products/constants';
import {
  selectCurrentPage,
  selectProductFromCode,
  selectProviderAttributionToken,
  selectProviderMetadata,
  selectTotalProducts,
} from 'src/redux/products/selectors';
import { requestProducts } from 'src/redux/products/thunks/requestProducts';
import { updateFilters, UpdateFiltersActionType } from 'src/redux/products/thunks/updateFilters';
import { TriggerName } from 'src/redux/products/types';
import { actions as visibilityTrackingActions } from 'src/redux/visibilityTracking/slice';
import { getProductImpressionsTrackingEvent } from 'src/tracking/events/productImpression';
import type { ClearEcommerceObject } from 'src/types/googleAnalytics';
import { getImpressionFieldObject } from 'src/utils/googleAnalytics';
import { transformProductsToGAReturnedProductsArray } from 'src/utils/transformProductsToGAReturnedProductsArray';

type RequestProductsAction = ReturnType<(typeof requestProducts)['fulfilled']>;
type UpdateFiltersAction = ReturnType<(typeof updateFilters)['fulfilled']>;

const sendProductImpressionsEvent = (
  productImpressionQueue: number[],
  state: ReduxApplicationState,
) => {
  const { navigation, products, productsListQuery } = state;
  const { items, productsReturnedCount } = products;
  const { categoryIDs, searchTerm } = productsListQuery;
  const { pageType } = navigation;
  const categoryId = categoryIDs?.[0];
  const categoryName = products.category?.name || '';

  if (Object.keys(items).length == 0) {
    return;
  }

  sendGAEvent<ClearEcommerceObject>({
    ecommerce: null,
  });

  const productImpressionsEvent = getProductImpressionsTrackingEvent({
    eventCategory: 'Product listings',
    eventAction: 'Product Impressions',
    eventLabel: 'Impressions',
    impressions: productImpressionQueue.map((productCode) => {
      const product = selectProductFromCode(state, productCode);

      return getImpressionFieldObject(
        product!,
        items,
        productsReturnedCount,
        searchTerm!,
        pageType,
        categoryId,
        categoryName,
        {
          ...(product?.isExpandedSearch && { listSuffix: ' - expanded' }),
        },
      );
    }),
  });

  sendGAEvent(productImpressionsEvent);
};

export const GAEventsMap: EventsMap<ReduxApplicationState> = {
  [visibilityTrackingActions.sendProductImpressionBatch.type]: (prevState, _, __, dispatch) => {
    const { productImpressionQueue } = prevState.visibilityTracking;

    if (!isBrowser() || productImpressionQueue.length === 0) {
      return;
    }

    sendProductImpressionsEvent(productImpressionQueue, prevState);

    // Clear the impression queue in the store
    dispatch(visibilityTrackingActions.clearProductImpressionQueue());
  },
  [requestProducts.pending.type]: (_prevState, _nextState, _action, dispatch) => {
    dispatch(visibilityTrackingActions.sendProductImpressionBatch());
  },
  [visibilityTrackingActions.onProductVisible.type]: (prevState, nextState, _action, dispatch) => {
    const { productImpressionQueue } = nextState.visibilityTracking;

    if (productImpressionQueue.length === PRODUCT_IMPRESSION_BATCH_SIZE) {
      // We have reached the batch limit and should send the batch
      dispatch(visibilityTrackingActions.sendProductImpressionBatch());
      return;
    }

    if (!prevState.visibilityTracking.atLeastOneProductHasBecomeVisible) {
      // Send batch on an interval so we can ensure event is sent during inactivity
      setInterval(
        () => dispatch(visibilityTrackingActions.sendProductImpressionBatch()),
        PRODUCT_IMPRESSION_TIMEOUT_MS,
      );

      // Send batch if user navigates away from page
      window.onbeforeunload = () => {
        dispatch(visibilityTrackingActions.sendProductImpressionBatch());
        return; // Empty return needed to prevent dialog box showing before unload
      };
    }
  },
  [requestProducts.fulfilled.type]: (prevState, nextState, action) => {
    const { payload } = action as RequestProductsAction;
    if (!payload.data) {
      return;
    }

    const { data, triggerName } = payload;
    const { products } = data;

    const paginationTriggerNames = [
      TriggerName.LOAD_MORE_PRODUCTS,
      TriggerName.LOAD_PREVIOUS_PRODUCTS,
    ];
    const paginationAction = triggerName && paginationTriggerNames.includes(triggerName);

    if (paginationAction) {
      const attributionToken = selectProviderAttributionToken(prevState);
      const providerMetadata = selectProviderMetadata(prevState);
      const returnedProducts = products
        ? transformProductsToGAReturnedProductsArray(products)
        : null;

      const productImpressionsEvent = getProductImpressionsTrackingEvent({
        eventAction:
          triggerName === TriggerName.LOAD_PREVIOUS_PRODUCTS
            ? 'Load Previous Impressions'
            : 'Load More Impressions',
        eventCategory: 'Product listings',
        eventLabel: String(selectCurrentPage(prevState)),
        ...(attributionToken && { attributionToken }),
        ...(providerMetadata && { crsFilter: providerMetadata.filter }),
        ...(returnedProducts && { returnedProducts: returnedProducts }),
      });

      sendGAEvent(productImpressionsEvent);
    }
  },
  [updateFilters.fulfilled.type]: (prevState, nextState, action) => {
    const { payload } = action as UpdateFiltersAction;
    const {
      products: { currentPage, items },
    } = prevState;
    const attributionToken = selectProviderAttributionToken(prevState);
    const providerMetadata = selectProviderMetadata(prevState);
    const returnedProducts = items?.[currentPage]
      ? transformProductsToGAReturnedProductsArray(items[currentPage])
      : null;

    switch (payload) {
      case UpdateFiltersActionType.FILTER:
        const updatedTotalProducts = selectTotalProducts(prevState);
        const appliedFilterTitles = selectUserAppliedFilterTitles(nextState);

        sendGAEvent({
          event: 'custom_event',
          event_category: 'Filters',
          event_action: 'applied filter info',
          event_label: appliedFilterTitles,
          total_listing_items: updatedTotalProducts,
          ...(attributionToken && { attribution_token: attributionToken }),
          ...(providerMetadata && { crs_filter: providerMetadata.filter }),
          ...(returnedProducts && { returned_products: returnedProducts }),
        });
        break;
      case UpdateFiltersActionType.SORT:
        const selectedSortingOption = selectSelectedSortingOption(prevState);

        sendGAEvent({
          event: 'custom_event',
          event_category: 'Sort Results',
          event_action: 'sort by',
          event_label: selectedSortingOption?.title,
          ...(attributionToken && { attribution_token: attributionToken }),
          ...(providerMetadata && { crs_filter: providerMetadata.filter }),
          ...(returnedProducts && { returned_products: returnedProducts }),
        });
        break;
    }
  },
};
